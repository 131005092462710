import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

import Box from 'shopper/components/Box';

import LazyHydration from 'components/LazyHydration';

import { sendEvent } from 'lib/gtag';
import { toFormattedGaEventActionPrefix } from 'lib/string';
import { noop } from 'lib/utils';

const FeaturedOfferCard = dynamic(() => import('./FeaturedOfferCard'));
const OfferGridCard = dynamic(() => import('./OfferGridCard'));

const OfferCard = ({
  cardTitleAs = 'span',
  gaEventActionPrefix = null,
  gaEventCategory = null,
  offerId,
  offerLikes,
  offerTags = [],
  showFooter = true,
  featured = false,
  onImageClick = noop,
  onTitleClick = noop,
  ...rest
}) => {
  const onImageClickHandler = useCallback(
    (e) => {
      if (gaEventCategory) {
        sendEvent({
          category: gaEventCategory,
          action: `${toFormattedGaEventActionPrefix(
            gaEventActionPrefix
          )}product_image`,
        });
      }

      onImageClick(e);
    },
    [gaEventCategory, gaEventActionPrefix]
  );

  const onTitleClickHandler = useCallback(
    (e) => {
      if (gaEventCategory) {
        sendEvent({
          category: gaEventCategory,
          action: `${toFormattedGaEventActionPrefix(
            gaEventActionPrefix
          )}product_title`,
        });
      }

      onTitleClick(e);
    },
    [gaEventCategory, gaEventActionPrefix]
  );

  const offerCardProps = {
    cardTitleAs,
    gaEventCategory,
    likesCount: offerLikes,
    offerId,
    offerTags,
    showFooter,
    onImageClick: onImageClickHandler,
    onTitleClick: onTitleClickHandler,
    ...rest,
  };

  if (featured) {
    return (
      <Box className="rounded-4 border border-neutral-high-200 dark:border-neutral-low-200 lg:hover:ease-in">
        <LazyHydration placeholderSize={{ height: 442 }}>
          <FeaturedOfferCard {...offerCardProps} />
        </LazyHydration>
      </Box>
    );
  }

  return (
    <Box className="rounded-4 border border-neutral-high-200 dark:border-neutral-low-200 lg:hover:ease-in">
      <LazyHydration placeholderSize={{ height: 442 }}>
        <OfferGridCard {...offerCardProps} />
      </LazyHydration>
    </Box>
  );
};

OfferCard.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      typeName: PropTypes.string.isRequired,
      specialityName: PropTypes.string,
      level: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  cardTitleAs: PropTypes.string,
  gaEventCategory: PropTypes.string.isRequired,
  offerComments: PropTypes.number.isRequired,
  offerIsHighlight: PropTypes.bool.isRequired,
  offerImagePriority: PropTypes.bool,
  offerOldPrice: PropTypes.number,
  offerPhoto: PropTypes.string.isRequired,
  offerPrice: PropTypes.number.isRequired,
  offerPriceType: PropTypes.string.isRequired,
  offerPublished: PropTypes.string.isRequired,
  offerSlug: PropTypes.string.isRequired,
  offerStatusName: PropTypes.string.isRequired,
  offerUserVisibility: PropTypes.string.isRequired,
  offerTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  offerTitle: PropTypes.string.isRequired,
  showFooter: PropTypes.bool,
  storeDomain: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string.isRequired,
  userUsername: PropTypes.string.isRequired,
  onImageClick: PropTypes.func,
  onTitleClick: PropTypes.func,
};

export default memo(OfferCard);
